body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.art {
  width: 224px;
  height: 224px;
  border: 4px solid gray;
}
.lyrics-header-container {
  display: flex;
  background-image: url(https://i.kinja-img.com/gawker-media/image/upload/s--MLy6kV-l--/c_scale,f_auto,fl_progressive,q_80,w_800/rsz9lihsmbgoeviutfdu.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;
}

.lyrics-song-info {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lyrics-header {
  display: flex;
  width: 960px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px 0;
}

.lyrics-container {
  display: flex;
  flex-direction: column;
}

.lyrics-song-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.lyrics-song-lyric {
  font-size: 1.1em;
  line-height: 2;
  margin: 0;
}

.lyrics-song-lines {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 2;
}

.lyrics-song-container {
  display: flex;
  justify-content: center;
}

.lyrics-song {
  width: 960px;
  background-color: #fefefe;
}

.lyrics-song-inner {
  margin: 10px 24px;
  display: flex;
  padding-bottom: 120px;
}

.lyrics-header-container {
  display: flex;
  justify-content: center;
}

.lyrics-container {
  background-color: #eeeeee;
  display: flex;
  flex-grow: 2;
}

.lyrics-header-inner {
  display: flex;
  margin: 0 30px;
}

.lyrics-album-art {
  width: 224px;
  height: 224px;
  border: 4px solid gray;
}

.lyrics-song-title {
  color: #ffff64;
}

.lyrics-song-info h2 {
  color: white;
}

.song-share-container {
}

.song-share-inner {
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  display: flex;
  padding: 20px;
  margin: 8px;
  border: 3px solid black;
}

.song-share-input {
  font-size: 1em;
}

.song-share-title {
  margin: 0;
  padding: 8px 0;
}



.song-footer {
  height: 120px;
  background-color: black;
}

.song-container {
  display: flex;
  justify-content: center;
}

.song-inner {
  display: flex;
  flex-direction: column;
  width: 960px;
}

.song {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.song-art {
  width: 224px;
  height: 224px;
  
}

.song-generate-button-container {
  display: flex;
  justify-content: center;
}

.nav-container {
  background-color: #f1c40f;
  display: flex;
  justify-content: center;
}

.nav-logo > img {
  width: 64px;
  height: 64px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  user-drag: none;
}

.nav-container a {
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1em;
}

.nav-container a:hover {
  text-decoration: underline;
  /*text-decoration-style:;*/
}

/*.nav-chevron {
  width: 50%;
  height: 80px;
  background-image: -webkit-linear-gradient(330deg, #f1c40f 75%, rgba(0, 0, 0, 0) 25%);
}
*/
.nav-item {
  display: inline-block;
  padding: 12px 8px;
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-container-inner {
  width: 960px;
}

.home-container {
  display: flex;
  justify-content: center;
}

.home-inner {
  width: 960px;  
}

.beat {
  width: 100%;
}
.song-footer {
  height: 120px;
  background-color: black;
}

.song-beat-container {
  position: fixed;
  bottom: 48px;
  width: 900px;
  display: flex;
}

.song-footer {
  height: 200px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

