.song-footer {
  height: 120px;
  background-color: black;
}

.song-beat-container {
  position: fixed;
  bottom: 48px;
  width: 900px;
  display: flex;
}

.song-footer {
  height: 200px;
}
