.song-footer {
  height: 120px;
  background-color: black;
}

.song-container {
  display: flex;
  justify-content: center;
}

.song-inner {
  display: flex;
  flex-direction: column;
  width: 960px;
}

.song {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.song-art {
  width: 224px;
  height: 224px;
  
}

.song-generate-button-container {
  display: flex;
  justify-content: center;
}
