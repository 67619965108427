.nav-container {
  background-color: #f1c40f;
  display: flex;
  justify-content: center;
}

.nav-logo > img {
  width: 64px;
  height: 64px;
  user-select: none;
  user-drag: none;
}

.nav-container a {
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1em;
}

.nav-container a:hover {
  text-decoration: underline;
  /*text-decoration-style:;*/
}

/*.nav-chevron {
  width: 50%;
  height: 80px;
  background-image: -webkit-linear-gradient(330deg, #f1c40f 75%, rgba(0, 0, 0, 0) 25%);
}
*/
.nav-item {
  display: inline-block;
  padding: 12px 8px;
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-container-inner {
  width: 960px;
}
