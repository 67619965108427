.lyrics-header-container {
  display: flex;
  background-image: url(https://i.kinja-img.com/gawker-media/image/upload/s--MLy6kV-l--/c_scale,f_auto,fl_progressive,q_80,w_800/rsz9lihsmbgoeviutfdu.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;
}

.lyrics-song-info {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lyrics-header {
  display: flex;
  width: 960px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px 0;
}

.lyrics-container {
  display: flex;
  flex-direction: column;
}

.lyrics-song-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.lyrics-song-lyric {
  font-size: 1.1em;
  line-height: 2;
  margin: 0;
}

.lyrics-song-lines {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 2;
}

.lyrics-song-container {
  display: flex;
  justify-content: center;
}

.lyrics-song {
  width: 960px;
  background-color: #fefefe;
}

.lyrics-song-inner {
  margin: 10px 24px;
  display: flex;
  padding-bottom: 120px;
}

.lyrics-header-container {
  display: flex;
  justify-content: center;
}

.lyrics-container {
  background-color: #eeeeee;
  display: flex;
  flex-grow: 2;
}

.lyrics-header-inner {
  display: flex;
  margin: 0 30px;
}

.lyrics-album-art {
  width: 224px;
  height: 224px;
  border: 4px solid gray;
}

.lyrics-song-title {
  color: #ffff64;
}

.lyrics-song-info h2 {
  color: white;
}

.song-share-container {
}

.song-share-inner {
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  display: flex;
  padding: 20px;
  margin: 8px;
  border: 3px solid black;
}

.song-share-input {
  font-size: 1em;
}

.song-share-title {
  margin: 0;
  padding: 8px 0;
}


